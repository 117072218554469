import React from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { useLabels, usePrefs } from 'ws-scripts/modules/common';
import { useFlags } from 'wsm-common-data';
import DropdownMenu from './DropdownMenu';
import MegaMenu from './MegaMenu';
import NavLink from './NavLink';
import { openMenu, closeAllMenus } from '../actions/menu';
import { setSelected } from '../utils/setSelected';
import { dispatchTrackingEvent } from '../utils/dispatchTrackingEvent';

const MenuItem = ({
	columns,
	index,
	isSiteWideSearchNavItem,
	label,
	labelAlias,
	numTopLevelItems,
	pageId,
	searchPosition,
	siteWidePositionFirst,
	siteWidePositionLast,
	subnavItems,
	target,
	url
}) => {
	const labels = useLabels();
	const {
		allowTopLevelLinks,
		dropdownDisplayType,
		enableMegaMenus,
		enableSiteWideSearch,
		homeItemClasses,
		navItemClasses,
		searchButtonIconSize,
		searchButtonLabel,
		searchButtonStyle,
		showDropdownIcon,
		showIcons,
		topLevelLinkClasses,
		'navigation.id': navId
	} = usePrefs();

	const dispatch = useDispatch();
	const flags = useFlags();

	const navsV2 = useSelector((state) => state.widgetData.navsV2);
	const openMenus = useSelector((state) => state.menu.openMenus);
	const currentPath = useSelector((state) => state.currentPage?.currentPath);

	// TODO: use state to hold outer/inner nav-item classes and attributes

	const homeIconClasses = ['ddc-icon', 'ddc-icon-home'].join(' ');
	const searchIconClasses = ['ddc-icon', 'ddc-icon-search'].join(' ');

	const isOpen = openMenus?.includes(index);
	const siteWideSearchEnabled = enableSiteWideSearch !== 'disabled';
	const footer = navId.toLowerCase().includes('footer');

	const isCurrentPage = !subnavItems?.length
		? Boolean(url === currentPath)
		: false; // account for top level navItems with children but have href assigned
	const isCurrentPageParent =
		isCurrentPage === false ? setSelected(subnavItems, currentPath) : false;

	// disallows simultaneous rendering of inventory search fragment and site-text-search
	if (
		siteWideSearchEnabled &&
		pageId === 'INVENTORY_SEARCH_FREE_FORM_FRAGMENT'
	) {
		return <></>;
	}

	// disallow rendering of broken navigation links in footer,
	// or if nav-item is top level without children
	if (
		(footer || !subnavItems?.length) &&
		(url === '/404.htm' || url === '/404.html')
	) {
		return <></>;
	}

	// Helper function to resolve menu item classes, attributes, and other pertinent data to correctly render.
	const getNavItemClassesAndAttributes = () => {
		const inventorySearchNavItem =
			pageId === 'INVENTORY_SEARCH_FREE_FORM_FRAGMENT' &&
			!siteWideSearchEnabled;
		let menuItemHref = '#';
		const homeNavItem = pageId === 'INDEX';
		const innerMenuItemClasses = [];
		const innerMenuAttributes = {};
		const outerNavItemClasses = [
			navItemClasses && navItemClasses !== 'blank' ? navItemClasses : ''
		];

		// home nav-item classing
		if (homeNavItem) {
			outerNavItemClasses.push('home');
			innerMenuItemClasses.push('homeLink');

			if (homeItemClasses.toLowerCase() !== 'blank') {
				outerNavItemClasses.push(homeItemClasses);
			}

			if (showIcons === 'true') {
				outerNavItemClasses.push('ddc-nav-icon-list-item');
				innerMenuItemClasses.push('ddc-nav-icon-link');
			}
		}

		// inventory search fragment nav-item classing
		if (inventorySearchNavItem) {
			outerNavItemClasses.push('search');

			if (showIcons === 'true') {
				outerNavItemClasses.push('ddc-nav-icon-list-item');
				innerMenuItemClasses.push('ddc-nav-icon-link');
			}
		}

		// nav-first/last nav-item classing
		if (
			// site-wide not enabled / is first nav-item
			(!siteWideSearchEnabled && index === 0) ||
			// site-wide enabled / site-wide item in last position / is first nav-item
			(siteWideSearchEnabled && siteWidePositionLast && index === 0) ||
			// site-wide enabled / is site-wide item in first position
			(siteWideSearchEnabled &&
				siteWidePositionFirst &&
				isSiteWideSearchNavItem)
		) {
			outerNavItemClasses.push('nav-first');
		}

		if (
			// site-wide not enabled / is last nav-item
			(!siteWideSearchEnabled && index === numTopLevelItems - 1) ||
			// site-wide enabled / site-wide item in first position / is last nav-item
			(siteWideSearchEnabled &&
				siteWidePositionFirst &&
				index === numTopLevelItems - 1) ||
			// site-wide enabled / is site-wide item in last position
			(siteWideSearchEnabled &&
				siteWidePositionLast &&
				isSiteWideSearchNavItem)
		) {
			outerNavItemClasses.push('nav-last');
		}

		// megamenu nav-item classing
		if (navsV2 || enableMegaMenus === 'true') {
			outerNavItemClasses.push(
				`dropdown dropdown ${
					isOpen && !isSiteWideSearchNavItem ? 'open' : ''
				}`
			);
		} else {
			outerNavItemClasses.push('nav-children dropdown');
		}

		// current page classing
		if (isCurrentPage) {
			outerNavItemClasses.push('currentPage');
		}

		if (isCurrentPageParent) {
			outerNavItemClasses.push('currentPageParent');
		}

		// fix urls
		if (
			!inventorySearchNavItem ||
			(!isSiteWideSearchNavItem &&
				(url !== '/404.htm' || url !== '/404.html'))
		) {
			menuItemHref = url?.replaceAll(/\s/g, '');
		}

		// site-wide search nav-item classing
		if (isSiteWideSearchNavItem) {
			const searchLabel = labels.get(searchButtonLabel);
			const searchTitle = labels.get('SEARCH_THE_WHOLE_SITE');
			innerMenuAttributes['aria-label'] = searchLabel
				? null
				: searchTitle;
			innerMenuAttributes.title = searchTitle;
			innerMenuAttributes.type = 'button';
			// data-testid for testing purposes
			innerMenuAttributes['data-testid'] = `search-${searchPosition}`;
			innerMenuItemClasses.push(
				`btn text-decoration-none btn-no-decoration btn-${searchButtonStyle}`
			);
		}

		// subnav-item classing
		if (!subnavItems?.length) {
			outerNavItemClasses.push('nav-no-children');
		}

		if (subnavItems?.length) {
			innerMenuAttributes['data-inactive'] = true;

			if (navsV2) {
				innerMenuItemClasses.push('nav-with-children');

				if (allowTopLevelLinks === 'false') {
					innerMenuAttributes['data-toggle'] = 'dropdown';
				}
			}

			if (!homeNavItem) {
				innerMenuAttributes['aria-expanded'] = isOpen;
			}
		}

		// add additional classes from preferences
		if (topLevelLinkClasses.toLowerCase() !== 'blank') {
			innerMenuItemClasses.push(topLevelLinkClasses);
		}

		return [
			outerNavItemClasses.join(' ').trim(),
			innerMenuItemClasses.join(' ').trim(),
			innerMenuAttributes,
			menuItemHref,
			homeNavItem,
			inventorySearchNavItem
		];
	};

	const toggleDropdown = () => {
		dispatch(closeAllMenus());

		if (typeof window.jQuery === 'function') {
			$('.navbar-nav li.dropdown')
				.eq(index)
				.trigger('hidden.bs.dropdown');
		}

		if (!isOpen && subnavItems.length > 0) {
			dispatch(openMenu(index));
		}
	};

	// Helper function to return the content inside a menu item based on position of Icon.
	const getMenuItemContent = (homeNavItem, inventorySearchNavItem) => {
		let innerMenuContent = '';
		// prefer getHTML, if label value exists, as this method supports localization and all characters
		const innerMenuText = labels.has(labelAlias)
			? labels.getHTML(labelAlias)
			: label;

		if (showIcons === 'true' && homeNavItem) {
			innerMenuContent = (
				<>
					<i aria-hidden="true" className={homeIconClasses} />
					<span className="sr-only">{innerMenuText}</span>
				</>
			);
		} else if (showIcons === 'true' && inventorySearchNavItem) {
			innerMenuContent = (
				<>
					<i
						aria-hidden="true"
						className={`${searchIconClasses} ddc-icon-size-${searchButtonIconSize}`}
					/>
					<span className="sr-only">{innerMenuText}</span>
				</>
			);
		} else if (isSiteWideSearchNavItem) {
			innerMenuContent = <i className={searchIconClasses} />;
		} else {
			innerMenuContent = innerMenuText;
		}
		return innerMenuContent;
	};

	// This is a reconstruction of the $shouldRender check
	if (flags['enable-no-empty-link-nav-items'] === true) {
		if (!!subnavItems?.length === false && url === false) {
			return <></>;
		}
	}

	const getMenuType = () => {
		if (subnavItems?.length) {
			if (enableMegaMenus === 'true') {
				return <MegaMenu index={index} columns={columns} />;
			} else {
				return <DropdownMenu subnavItems={subnavItems} />;
			}
		}
		return <></>;
	};

	const handleClick = (e) => {
		// As a note: This was added to prevent top-level nav-items from navigating out.
		// There is something in cms-web that also does this but we were unable
		// to locate the source and take advantage of it.
		// This may cause issues for us in the future.
		if (subnavItems?.length) {
			e.preventDefault();
		}

		if (isSiteWideSearchNavItem) {
			const event = new Event('ws-site-text-search-open-modal');
			e.preventDefault();
			document.dispatchEvent(event);
		}
	};

	const dropDownProps =
		dropdownDisplayType === 'hover'
			? {
					onMouseEnter: () => {
						toggleDropdown();
					}
			  }
			: {
					onClick: (e) => {
						dispatchTrackingEvent(e);
						e.stopPropagation();
						toggleDropdown();
					}
			  };

	const [
		outerClasses,
		innerClasses,
		innerAttributes,
		menuHref,
		isHome,
		isSearch
	] = getNavItemClassesAndAttributes();

	const getLinkContent = () => {
		return (
			<>
				{getMenuItemContent(isHome, isSearch)}
				{!!subnavItems?.length && showDropdownIcon === 'true' && (
					<b className="caret" />
				)}
			</>
		);
	};

	const children = getLinkContent();

	const innerAttrs = { ...innerAttributes };
	innerAttrs.onClick = handleClick;

	const outerElAttrs = enableMegaMenus === 'true' ? dropDownProps : () => {};
	// managing NMM events at a widget level interferes with cms-web inheritance

	if (isCurrentPageParent) {
		outerElAttrs['aria-current'] = 'page';
	}

	outerElAttrs.className = isCurrentPageParent
		? `${outerClasses} currentPageParent`
		: outerClasses;

	return (
		<li {...outerElAttrs}>
			<NavLink
				additionalAttrs={innerAttrs}
				classes={
					isCurrentPage ? `${innerClasses} currentPage` : innerClasses
				}
				target={target}
				topLevelNavIndex={index}
				url={menuHref}
			>
				{children}
			</NavLink>
			{getMenuType()}
		</li>
	);
};

MenuItem.propTypes = {
	columns: PropTypes.arrayOf.isRequired,
	index: PropTypes.string.isRequired,
	isSiteWideSearchNavItem: PropTypes.bool.isRequired,
	label: PropTypes.string.isRequired,
	labelAlias: PropTypes.string.isRequired,
	numTopLevelItems: PropTypes.string.isRequired,
	pageId: PropTypes.string.isRequired,
	searchPosition: PropTypes.string.isRequired,
	siteWidePositionFirst: PropTypes.bool.isRequired,
	siteWidePositionLast: PropTypes.bool.isRequired,
	subnavItems: PropTypes.objectOf({
		id: PropTypes.string.isRequired,
		url: PropTypes.string.isRequired,
		label: PropTypes.string.isRequired,
		navigationItems: PropTypes.array.isRequired
	}),
	target: PropTypes.string.isRequired,
	url: PropTypes.string.isRequired
};

export default MenuItem;
